.footer-page {
  display: flex;
  justify-content: center;
  position: fixed;
  background: #1a2224;
  width: 100%;
  bottom: 0;
  height: 26px;
  .space-160 {
    width: 160px;
  }
  .footer-container {
    display: flex;
    padding-left: 160px;
    width: 100%;
    justify-content: space-between;
    max-width: 128rem;
    .left-block {
      a {
        text-decoration: underline;
        font-size: 12px !important;
        color: $color-green;
      }
      a:first-child{
        margin-right: 80px;
      }     
    }
    .right-block {
      color: #fff;
      font-size: 12px;
      line-height: 24px;
      padding-right: 25px;
    }
  }
}
@media (max-width: $screen-lg) {
  .footer-page {
    .footer-container {
      padding-left: 25px !important;
      .right-block {
        padding-right: 165px;
      }
    }
    .space-160 {
      width: 25px;
    }
  }
}
@media (max-width: $screen-sm) {
  .footer-page {
    display: none;
  }
}