
.ant-modal {
  width: unset !important;
  max-width: $content-width;
}
@media (max-width: 767px) {
  .ant-modal {
    margin: 24px auto; 
  } 
}

.ant-modal-content {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}

.ant-modal-body {
  border-radius: 10px;
  background-color: $bg-primary;
  padding: 0;
}

.ant-modal-title {
  @extend .pb-5;
  @extend .mb-5;
  border-bottom: 1px solid $border-primary;
  width: 100%;
  text-align: center;
}

.ant-modal-close {
  top: -27px;
  right: -5px;

  .ant-modal-close-x {
    .anticon {
      background-color: #FFFFFF;
      border-radius: 50%;
      padding: 2px;
      font-size: 20px;
    }
    .anticon svg {
      border: 1px solid rgba(0, 0, 0, 0.45);
      border-radius: 50%;
      padding: 2px;
    }
  }
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw);
    width: calc(100vw);
    padding: 0 16px;
  }
}