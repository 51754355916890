.ant-picker-cell .ant-picker-cell-inner,
.ant-picker-date-panel .ant-picker-content th {
  text-transform: capitalize;
}

.ant-picker-header {
  button {
    color: $color-dark;
    text-transform: capitalize;
  }

  .ant-picker-month-btn,
  .ant-picker-month-btn:hover,
  .ant-picker-year-btn,
  .ant-picker-year-btn:hover {
    color: $color-primary
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
  }
}

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  width: 8px;
  height: 8px;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  width: 8px;
  height: 8px;
}

.ant-picker-cell .ant-picker-cell-inner {
  line-height: 25px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: transparent;
  border: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  color: #E75012;
  font-weight: bold;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: $color-primary;
  border-radius: 50%;
}