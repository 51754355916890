
.ant-radio-inner {
  border-color: $color-dark;
  border-width: 1px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $color-primary;
  border-width: 5px;
}

.ant-radio-inner::after {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: -6px;
  background-color: white;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner,
.ant-radio-wrapper:focus .ant-radio,
.ant-radio:focus .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: $color-primary;
}

.ant-radio-wrapper {
  padding: 12px;
  margin-right: 0;
  width: 345px;

  @include media-screen(xs) {
    width: 100%;
  }
}

.ant-radio-wrapper:first-child {
  border-bottom: 1px solid #D5D5D5;

}

.ant-radio-wrapper:nth-child(2) {
  border-left: 1px solid #D5D5D5;
  border-bottom: 1px solid #D5D5D5;

  @include media-screen(xs) {
    border-left: none;
  }
}

.ant-radio-wrapper:nth-child(3) {
  @include media-screen(xs) {
    border-bottom: 1px solid #D5D5D5;
  }
}

.ant-radio-wrapper:last-child {
  border-left: 1px solid #D5D5D5;

  @include media-screen(xs) {
    border-left: none;
    border-bottom: none;
  }
}