@font-face {
  font-family: roboto;
  src: url(./Roboto-Regular.ttf);
}

@font-face {
  font-family: roboto-medium;
  src: url(./Roboto-Medium.ttf);
}

@font-face {
  font-family: roboto-italic;
  src: url(./Roboto-Italic.ttf);
}

@font-face {
  font-family: roboto-bold;
  src: url(./Roboto-Bold.ttf);
}