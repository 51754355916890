.ant-checkbox-wrapper {
  font: $font-content-sx;
  color: $color-dark;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 20px;
  height: 20px;
}

.ant-checkbox-inner {
  // border-color: transparent;
  // border: none !important;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  transition: none;

  &::after {
    top: 48%;
    left: 24%;
    width: 6px;
    height: 12px;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #d9d9d9;
}

.ant-checkbox-checked {
  &::after {
    border: 2px solid $color-primary;
  }

  .ant-checkbox-inner {
    background-color: $color-primary;
    border-color: #d9d9d9;
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent;
}