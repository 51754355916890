.chantier {
  &__search {
    @extend .mb-6;
    display: flex;
    justify-content: flex-end;

    .input-search {
      width: 100% !important;
      // max-width: 425px;
      padding: 8px 12px 8px 18px;
    }
  }

  &-card {
    @extend .mb-6;
    // @extend .px-4;
    @extend .pt-2;
    @extend .pb-1;
    background: $color-white;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 5px;
    .ant-spin-container > .ant-row > div:last-child .chantier-card__row {
      border-bottom: none !important;
    }
    &__row {
      border-bottom: 1px solid #f0f0f0 !important;
      padding: 20px !important;
      margin-bottom: 0px !important;
    }

    &__title {
      font: $font-h4;
      color: $text-dark;
    }

    &__subtitle {
      font: normal normal normal 12px/24px $font-family;
      color: $color-primary;
    }

    &__content {
      font: normal normal normal 12px/24px $font-family;
      color: $text-grey;

    }

    &__date {
      font: normal normal 600 16px/20px $font-family;
      color: $color-primary;

    }

    &__code {
      font: $font-h4;
      color: $color-primary;

    }
  }

}
.chantier-detail {
  .container {
    &.second-step {
      min-height: calc(100vh - 120px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.benne {
  &-card {
    @extend .my-5;
    @extend .radius;
    background: $color-white;
  }
  &-row {
    @extend .py-5;

    &.border-bottom {
      border-bottom: 1px solid $border-grey;

      @include media-screen(xs) {
        border-bottom: none;
      }
    }
  }
  &-col {
    @extend .px-5;
    border-right: 1px solid $border-grey;

    @include media-screen(xs) {
      margin-bottom: 12px;
      border-right: none;
    }
    &:last-child {
      border-right: none;
    
      @include media-screen(xs) {
        margin-bottom: 0px;
      }
    }
  }
  &-button {
    @include media-screen(xs) {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      min-width: unset;
      margin-right: 24px;
      margin-left: 24px;
    }

    @media (min-width: $screen-sm)  {
      min-width: 42%;
      border-radius: 16px;
    }

    &:hover,
    &:active,
    &:focus {
      @media (min-width: $screen-sm)  {
        border-radius: 16px;
      }
  
    }

    &:last-child {
      @include media-screen(xs) {
        margin-bottom: 0px;
      }
    }
  }
}

.confirmer {
  &-buttons {
    @extend .pt-3;
    padding-bottom: 35px;
    padding-top: 40px !important;
    background: $color-white;
    
    .ant-row {
      @extend .py-2;
      margin: 0 auto;
      @include media-screen(xs) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  &-card {
    @extend .my-5;
    @extend .radius;
    background: $color-white;
  }
  &-row {
    @extend .py-5;

    &.border-bottom {
      border-bottom: 1px solid $border-grey;
    }
  }
  &-col {
    @extend .px-5;
    border-right: 1px solid $border-grey;

    @include media-screen(xs) {
      margin-bottom: 12px;
      border-right: none;
    }
    &:last-child {
      border-right: none;
    }
  }
  &-modal {
    .ant-result {
      min-width: 400px;
      @include media-screen(xs) {
        min-width: 100%;
      }
    }
    .ant-result-title {
      color: #000000;
      font: normal normal 600 19px/23px $font-family-semibold;
      margin-bottom: 30px;
    }
    .ant-result-subtitle {
      color: #000000;
      font: normal normal 400 15px/18px $font-family;
    }
    .ant-result-extra {
      margin-top: 40px;
      > * {
        margin-right: 0;
      }
    }
  }
}

.selectDate {
  &-modal {
    @extend .py-5;
    @extend .px-8;
    // overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  &-content {
    @extend .flex-justify-between;
    margin-bottom: 20px;
    @include media-screen(xs) {
      margin-bottom: 50px;
    }
  }

  &-benne {
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media-screen(xs) {
      margin-left: 0 !important;
      margin-top: 32px;
    }
    
    > div {
      flex: 1 1;
      flex-direction: column;
    }
  }
}

.precautions-list {
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }

  .ant-list-split .ant-list-item,
  .ant-list-item {
      border-bottom: none;
      padding: 4px 0 0;
  }
  .ant-list-item-meta-title {
    font-size: 10px;
    margin-bottom: 0;
    margin-top: 4px;
  }
}
@media (max-width: 767px) {
  .confirmer {
    &-buttons {
      padding-bottom: 20px;
    }
  }
}