.header {
  // background-color: $bg-primary;
  @extend .px-4;
  @extend .py-0;
  max-width: $content-width + 20rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding-left: 160px !important;
  align-items: center;
  justify-content: space-between;
  .logo {
    display: block;
  }
  .responsive-logo {
    display: none;
  }
  &__wrapper {
    background-color: $bg-dark;
    height: 54px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99;
    .container-info-user {
      background-color: #F5F5F5;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 175px;
      min-width: 175px;
      cursor: pointer;
      max-width: 200px;
      padding-left: 2px;
      padding-right: 2px;
      color: $color-green;
      text-transform: uppercase;
    }
    .contact-info-container {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 53px;
        top: 0;
        .tel-link {
          // width: 50px;
          padding-right: 15px;
          display: flex;
          color: #fff;
        }
    }
    .container-info-phone{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-color: #95C515;
        width: 138px;
        height: 53px;
    }
    .contact-phone {
        background-image: url(../../../assets//icons/phone.svg);
        width: 42px;
        height: 21px;
        margin-left: 60px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    @media screen and (max-width: 1200px) {
        .contact-phone {
            margin-left: 30px;
        }
    }
    .contact-email {
        background-image: url(../../../assets/icons/mail.svg);
        width: 48px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
    }
  }

  &__menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    width: 54px;
    height: 48px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &-popover {
    background-color: $bg-dark;
    // max-width: 375px;
    width: 100%;
    .profile {
      @extend .pb-12;

      &__name {
        text-align: right;
        font: normal normal bold 22px/27px $font-family-semibold;
        letter-spacing: 0px;
        color: #95C515;
      }

      &__title {
        text-align: right;
        font: normal normal 600 14px/17px $font-family;
        letter-spacing: -0.42px;
        color: #95C515;
      }
    }
    .ant-menu-inline .ant-menu-item:first-child {
      border-top: none;
    }
  }

}

.header-navbar {
  background-color: $bg-dark;
  .user-name {
    color: $color-green;
    text-transform: uppercase;
  }
  &__icon {
    @extend .ml-8;
  }
}

.ant-menu-item,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  border-top: 1px solid #e0e0e0;
  width: calc(100%);
  padding: 0 !important;
  margin: 0 !important;
}

.ant-menu-inline>.ant-menu-item {
  height: auto;
  line-height: 77px;
  .ant-menu-title-content {
    color: #000;
  }
  .tel-contact {
    color: $color-green !important;
    border-bottom: 1px solid $color-green;
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
  border-color: transparent;
}

.ant-menu-item,
.ant-menu-item a,
.ant-menu-item a:hover,
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  font: normal normal normal 18px/22px $font-family;
  letter-spacing: -0.54px;
  color: $color-white;
}

.ant-menu-item-disabled,
.ant-menu-item-disabled a {
  color: $text-grey !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
  border-color: transparent;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $color-white !important;
}

.page-title {
  @extend .px-4;
  @extend .py-0;
  max-width: $content-width;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__wrapper {
    background-color: $color-white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
    position: relative;
    z-index: 99;
  }

  &__content {
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
  }
  &__steps-icon {
    svg {
      text {
        font-family: $font-family-medium !important;
      }
    }
  }
}

.page-title__btn-back,
.btn-back {
  @extend .pa-0;
  text-align: center;
  letter-spacing: 0px;
  color: $text-dark;
  display: flex;
  align-items: center;
  font: normal normal normal 16px/20px $font-family;
  margin-left: -7px;

  &:hover {
    color: $text-dark
  }
}


.page-title__steps {
  display: block;

  @include media-screen(xs) {
    display: none;
  }
}

.page-title__steps--small {
  display: none;
  text-align: right;
  h3 {
    white-space: nowrap;
  }

  @include media-screen(xs) {
    display: block;
  }

  .page-titl__steps-icon {
    width: 48px;
    height: 48px;
  }
}
@media (max-width: $screen-lg) {
  .header {
    padding-left: 25px !important;
  }
}
@media (max-width: $screen-sm) {
  .header {
    padding-right: 0px !important;
    .logo {
      display: none;
    }
    .responsive-logo {
      display: block;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 44px;
      width: auto;
    }
    &__wrapper {
      .container-info-user {
        display: none;
      }
      .contact-info-container {
        display: none;
      }
    }
    &__menu-button {
      display: flex;
      padding-right: 15px;
      &.ant-popover-open {
        padding-right: 0;
        padding-left: 10px;
        color: black;
        height: 54px;
        width: 78px;
        background-image: url(../../../assets/icons/close-container.svg);
        background-position: left;
        background-size: cover;
      }
    }
  }
  .page-title {
    &__content {
      justify-content: end;
    }
  }
}