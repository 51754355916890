.ant-popover {
  z-index: 999;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-inner {
  background-color: white;
  border-radius: 0 0 16px 16px;
  box-shadow: none;

  // @include media-screen(xs) {
  //   border-radius: 0;
  //   height: calc(100vh - 60px);
  // }
}

.ant-popover-inner-content {
  @extend .pa-7;
}

.ant-popover.ant-popover-placement-bottomRight {
  padding-top: 0 !important;
  top: 54px !important;
  width: 100%;
  height: 100%;
  color: #000000;
  .ant-popover-content {
    height: calc( 100vh - 54px );
    .ant-popover-inner {
      height: 100%;
      border-radius: 0;
    }
  }
  .header-popover {
    background-color: transparent;
  }
  .header-navbar {
    background-color: transparent;
  }
  .ant-menu-item a {
    color: #000000;
  }
}

.mask-overlay {
  width: 100vw;
  height: 100vh;
  background: #BCBCBC 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}