@font-face {
    font-family: 'inter-Thin';
    src: url('Inter-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'inter-black';
    src: url('Inter-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'inter-bold';
    src: url('Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'inter-extrabold';
    src: url('Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'inter-extralight';
    src: url('Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'inter-light';
    src: url('Inter-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'inter-medium';
    src: url('Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'inter-regular';
    src: url('Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'inter-semibold';
    src: url('Inter-SemiBold.ttf') format('truetype');
}