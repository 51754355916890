@import 'layout/index';
@import './typography';
@import './button';
@import './form';
@import './input';
@import './radio';
@import './popover';
@import './page-header';
@import './steps';
@import './tabs';
@import './calendar';
@import './modal';
@import './checkbox';
@import './datepicker';
@import './switch';




.ant-divider {
  border-top: 1px solid #D5D5D5;
}

.ant-picker-clear {
  right: 28px;
}