.service {
  padding: 64px 0;

  @include media-screen(xs) {
    padding: 12px 0;
  }
}

.order-summary {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  max-width: 690px;
  margin: 0 auto;

  @include media-screen(xs) {
    grid-template-columns: auto;
  }

  &__card {
    background-color: $color-white;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 5px;
    padding: 0;

    &-content {
      padding: 20px 12px;
    }
  }

  &__title {
    font: normal normal normal 16px/18px $font-family-semibold;
    color: $text-secondary;
  }

  &__subtitle {}
}