.button-card {
  @extend .mb-6;
  // padding: 6% 14%;
  padding: 20px 30px !important;
  background: $color-white;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 5px;
  cursor: pointer;

  &__name,
  &__title {
    font: $font-h4;
    color: $text-dark;
  }

  &__subtitle {
    font: normal normal 600 12px/24px $font-family-semibold;
    color: $color-primary;
  }
}